/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useRef, useState } from "react";
import "./App.scss";
import { LiveAPIProvider } from "./contexts/LiveAPIContext";
import SidePanel from "./components/side-panel/SidePanel";
import { Altair } from "./components/altair/Altair";
import ControlTray from "./components/control-tray/ControlTray";
import cn from "classnames";
 
const API_KEY = process.env.REACT_APP_GEMINI_API_KEY as string;
if (typeof API_KEY !== "string") {
  throw new Error("set REACT_APP_GEMINI_APIK_KEY in .env");
}

const host = "generativelanguage.googleapis.com";
const uri = `wss://${host}/ws/google.ai.generativelanguage.v1alpha.GenerativeService.BidiGenerateContent`;

function App() {
  // this video reference is used for displaying the active stream, whether that is the webcam or screen capture
  // feel free to style as you see fit
  const videoRef = useRef<HTMLVideoElement>(null);
  // either the screen capture, the video or null, if null we hide it
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);

  return (
    <div className="App">
      <LiveAPIProvider url={uri} apiKey={API_KEY}>
        <div className="streaming-console">
          <div className="hidden">
            <SidePanel />
          </div>
          <main>
          <header>
            <a href="https://www.twintivo.com">
                <img src="logo.png" alt="logo" className="max-w-64 mt-5 lg:max-w-sm"/>
            </a>
            <h3 className="text-right pr-2">Your live assistant! </h3>
          </header>
              <div className="main-app-area">
              <section className="flex flex-col items-center justify-center">
              <div className="flex flex-col items-center justify-center">
              <h1 className="lg:text-4xl text-2xl font-bold text-center text-red-500 p-2 lg:hidden">Mobile comming soon ...</h1>                
              <h1 className="lg:text-4xl text-xl font-bold text-right text-cyan-500 pb-10 lg:hidden">Try in desktop ...</h1>                
                <h1 className="lg:text-4xl text-2xl font-bold text-center text-cyan-100">Ask me anything about manufacturing</h1>
              </div>
              <section className="lg:text-xl text-md font-bold text-center text-yellow-50 mb-10">
              You can enhance your live experience by using your voice, and sharing your camera and screen.
              </section>
            </section>
              <Altair />
              <video
                className={cn("stream", {
                  hidden: !videoRef.current || !videoStream,
                })}
                ref={videoRef}
                autoPlay
                playsInline
              />
            </div>
              <div>
              <ControlTray
                videoRef={videoRef}
                supportsVideo={true}
                onVideoStreamChange={setVideoStream}
              >
              {/* put your own buttons here */}
            </ControlTray>
            </div>
          </main>
        </div>
      </LiveAPIProvider>
    </div>
  );
}

export default App;
